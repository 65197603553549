<template>
    <div class="container mx-auto bg-base-200 bg-opacity-50">
      <div class="flex flex-col w-full min-h-screen">
        <div class="flex-none sticky top-0 z-[1]">
          <NavBar/>
        </div>
        <div class="flex-1">
          <slot></slot>
          <!--add right bottom whatsapp button-->
          <div class="fixed bottom-12 right-8">
            <a v-if="whatsapp" :href="'https://wa.me/'+whatsapp" target="_blank" class="btn btn-circle btn-ghost btn-md lg:hidden p-0">
              <Icon name="logos:whatsapp-icon" class="w-full h-full"/>
            </a>
          </div>
        </div>
        <div class="flex-none">
          <Footer/>
        </div>
      </div>
  
    </div>
  </template>
  <script setup lang="ts">
  const {whatsapp, GetSiteSettings} = useSiteSettings()
  await GetSiteSettings()
  </script>
  